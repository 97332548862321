<template>
    <div class="Withdraw flex-column box1" :class="[screenWidth > 1000 ? '' : 'Withdraw2']">
        <div class="ptitle flex-between">
            <p class="flex-between">
                <img src="../../assets/img/asset/bzxz.png" alt="" />
                <span>{{ lang == 'en' ? 'Currency selection' : '提现币种' }}</span>
            </p>
            <div class="flex">
                <span style="padding-right: 8px">{{ coinConfig[coinid]['coinname'] }}</span>
                <!-- <a-icon type="down" /> -->
            </div>
        </div>
        <div class="addressBox flex-column">
            <p class="flex">
                <img src="../../assets/img/asset/bzxz.png" alt="" />
                <span>{{ lang == 'en' ? 'Withdrawal address' : '提币地址' }}</span>
            </p>
            <div>
                <input :placeholder="lang == 'en' ? 'Enter the address' : '输入地址'" v-model="toAddress" type="text" />
            </div>
        </div>
        <div class="addressBox flex-column">
            <p class="flex">
                <img src="../../assets/img/asset/bzxz.png" alt="" />
                <span>{{ lang == 'en' ? 'The amount to be withdrawn' : '提现数量' }}</span>
            </p>
            <div>
                <input :placeholder="lang == 'en' ? 'Enter the amount' : '输入数量'" v-model="amount" type="text" />
                <span style="cursor: pointer" @click="maxFn">MAX</span>
            </div>
            <p class="balance">
                <span>{{ lang == 'en' ? 'Balance:' : '余额：' }}{{ selectBalance.balance }}</span>
            </p>
        </div>
        <div class="flex-between feeBox" v-if="fee">
            <p class="flex">
                <img src="../../assets/img/asset/sxf.png" alt="" />
                <span>{{ lang == 'en' ? 'Fee:' : '手续费：' }}</span>
            </p>
            <span>{{ fee * 100 }}%</span>
        </div>
        <p class="flex-column tipBox" :class="[screenWidth > 1000 ? '' : 'left']">
            <span v-if="Number(minout)">* {{ `${lang == 'en' ? 'The minimum withdrawal amount is' : '最少提现数量为'}${minout}；` }}</span>
            <span v-if="Number(maxout)">* {{ `${lang == 'en' ? 'The maximum withdrawal amount is' : '最大提现数量为'}${maxout}；` }}</span>
            <span>
                {{
                    lang == 'en'
                        ? '* Please double-check whether the destination address is correct, once the withdrawal is made, it cannot be reversed'
                        : '* 请反复确认目标地址是否正确，提现一旦发出将无法撤回'
                }}
            </span>
        </p>
        <Mbutton class="btnBox" :disabled="btnDisabled" :loading="btnLoading" :value="btnText" @click="WithdrawApplyFn" />
        <p class="withDrawRecord" @click="$router.push('AssetDetail' + '?coinid=' + coinid + '&type=2')">
            <span>{{ lang == 'en' ? 'Withdrawal record' : '提现记录' }}>></span>
        </p>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import Mbutton from '@/components/Mbutton.vue';
import { WithdrawApply } from '@/api/Trade.js';
import { getSign } from '@/web3utils/base/index.js';
import { GetKey } from '@/api/Users.js';
export default {
    data() {
        return {
            coinid: 1,
            toAddress: '',
            amount: '',
            btnLoading: false,
            signData: '',
        };
    },
    components: { Mbutton },
    computed: {
        ...mapState(['screenWidth', 'address', 'coinConfig', 'lang', 'balance','assetParams']),
        selectBalance() {
            return this.balance[this.coinid];
        },
        fee() {
            return this.coinConfig[this.coinid].feeout;
        },
        minout() {
            return this.coinConfig[this.coinid].minout;
        },
        maxout() {
            return this.coinConfig[this.coinid].maxout;
        },
        // btnText
        btnText() {
            if (!this.toAddress) return this.lang == 'zh' ? '请输入地址' : 'Please enter an address';
            if (!this.amount) return this.lang == 'zh' ? '请输入数量' : 'Please enter an amount';
            const amountB = BigInt(this.$toWei(this.amount, 10));
            const maxoutB = BigInt(this.$toWei(this.maxout, 10));
            const minoutB = BigInt(this.$toWei(this.minout, 10));
            if (maxoutB && amountB > maxoutB) return this.lang == 'zh' ? `最大提币数量：${this.maxout}` : `Maximum withdrawal amount: ${this.maxout}`;
            if (minoutB && amountB < minoutB) return this.lang == 'zh' ? `最小提币数量：${this.minout}` : `Minimum withdrawal amount: ${this.minout}`;
            let a = BigInt(this.$toWei(this.amount, 18) > BigInt(this.$toWei(this.selectBalance.balance, 18)));
            if (a) return this.lang == 'zh' ? '余额不足' : 'The balance is insufficient';
            return this.lang == 'zh' ? '提现' : 'Withdraw';
        },
        btnDisabled() {
            return false;
        },
    },
    async created() {},
    async mounted() {
        const { type, coinid } = this.assetParams;
        console.log('type', type);
        console.log('coinid', coinid);
        this.coinid = coinid || 1;
        this.init();
    },
    watch: {},
    methods: {
        maxFn() {
            const minoutB = BigInt(this.$toWei(this.minout, 10));
            const maxoutB = BigInt(this.$toWei(this.maxout, 10));
            const balanceB = BigInt(this.$toWei(this.selectBalance.balance, 10));
            if (maxoutB && balanceB > maxoutB) this.amount = this.maxout;
            if (minoutB < balanceB < maxoutB) return (this.amount = this.selectBalance.balance);
            if (minoutB && balanceB < minoutB) return (this.amount = this.minout);
            this.amount = this.selectBalance.balance;
        },
        async init() {},
        async WithdrawApplyFn() {
            if (!this.toAddress) return;
            if (!this.amount) return;
            let a = BigInt(this.$toWei(this.amount, 18) > BigInt(this.$toWei(this.selectBalance.balance, 18)));
            if (a) return;
            const amountB = BigInt(this.$toWei(this.amount, 10));
            const maxoutB = BigInt(this.$toWei(this.maxout, 10));
            const minoutB = BigInt(this.$toWei(this.minout, 10));
            if ((maxoutB && amountB > maxoutB) || (minoutB && amountB < minoutB)) return;
            this.btnLoading = true;
            this.signData = (await GetKey({ address: this.address })).data;
            let signMsg = await getSign(this.signData);
            if (!signMsg) return (this.btnLoading = false);
            let res = await WithdrawApply({
                amount: this.amount,
                toaddress: this.toAddress,
                coinid: this.coinid,
                signData: signMsg,
            });
            this.btnLoading = false;
            if (!res.success) return this.$message.error(res.msg);
            this.$message.success(res.msg);
            this.toAddress = '';
            this.amount = '';
            this.$router.push('AssetDetail' + '?coinid=' + this.coinid + '&type=2');
        },
    },
};
</script>
<style lang="scss" scoped>
.withDrawRecord {
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding-top: 20px;
    text-align: center;
    // height: 48px;
    // border-radius: 10px;
    // border: 1px solid #0421df;
    color: #0421df;
    // line-height: 48px;
}
.Withdraw {
    width: 100%;
    padding: 30px !important;
}
.Withdraw2 {
    padding: 20px !important;
}
.ptitle {
    padding-bottom: 20px;
    border-bottom: 1px solid #313131;
    & > p {
        margin-bottom: 0px !important;
        img {
            width: 24px;
            height: 24px;
            margin-right: 12px;
        }
        span {
            font-size: 15px;
        }
    }
}
.feeBox {
    & > p {
        margin-bottom: 0px !important;
        img {
            width: 24px;
            height: 24px;
            margin-right: 12px;
        }
        span {
            font-size: 15px;
        }
    }
    & > span {
        font-size: 15px;
    }
}
.addressBox {
    padding-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #313131;
    & > p {
        padding-bottom: 20px;
        margin-bottom: 0px !important;
        img {
            width: 24px;
            height: 24px;
            margin-right: 12px;
        }
        span {
            font-size: 15px;
        }
    }
    & > div:nth-of-type(1) {
        display: flex;
        input {
            flex: 1;
            height: 50px;
            box-sizing: border-box;
            padding: 10px 20px;
            background: #29292d;
            font-size: 18px;
            border-radius: 10px;
        }
        input::placeholder {
            font-size: 16px !important;
        }
        span {
            margin-left: 16px;
            width: 60px;
            background: #29292d;
            border-radius: 10px;
            text-align: center;
            line-height: 50px;
        }
    }
    .balance {
        margin-bottom: 0px !important;
        font-size: 14px;
        padding-top: 20px;
    }
}
.feeBox {
    padding: 30px 0px;
}
.btnBox {
    margin: 30px 0px 20px;
}
.tipBox {
    font-size: 13px;
    color: rgba(#fff, 0.8);
    span {
        width: 100%;
        text-align: center;
    }
}
.tipBox.left {
    span {
        text-align: left !important;
    }
}
</style>
