<template>
    <div class="all-medal-content2 flex-column">
        <div class="total">
            <p class="flex">
                <img src="../../assets/img/default/total.png" alt="" />
                <span>{{lang=='en'?'Valuation of total assets':'总资产估值'}}</span>
            </p>
            <span>
                <em>$</em>
                {{ $integer(totalCNY,6) }}
            </span>
        </div>
        <hr class="hr" />
        <div class="flex-1 teamList">
            <ul v-if="arrList.length > 0" class="grid-container3" :class="[screenWidth > 900 ? 'grid-container3' : 'mb12']">
                <li class="box3 cardBalance" v-for="item in arrList">
                    <div class="flex-between">
                        <p class="flex">
                            <img v-if="coinConfig[item.coinid].logourl == '0'" :src="defaultImg" alt="" />
                            <img v-else :src="coinConfig[item.coinid].logourl" alt="" />
                            <span>{{ coinConfig[item.coinid].coinname }}</span>
                        </p>
                        <p class="flex cursor-pointer" @click="$router.push('AssetDetail' + '?coinid=' + item.coinid)">
                            <span>{{lang=='en'?'Detail':'明细'}}</span>
                            <span>>></span>
                        </p>
                    </div>
                    <div class="flex-between">
                        <p>
                            {{ $integer(item.balance,6) }}
                        </p>
                        <div class="flex">
                            <button v-if="Number(coinConfig[item.coinid].istransfer) == 1" @click="transferFn(item)">{{lang=='en'?'Transfer':'转账'}}</button>
                            <button v-if="Number(coinConfig[item.coinid].isout) == 1" @click="withdrawFn(item)">{{lang=='en'?'Withdraw':'提现'}}</button>
                        </div>
                    </div>
                    <p class="flex">
                        <span>{{lang=='en'?'Freeze:':'冻结：'}}{{ $integer(item.lockbalance,6) }}</span>
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import CustomPagination from '@/components/CustomPagination';
import empty from '@/components/empty';
import { mapState } from 'vuex';
import { teamtotal, Balance } from '@/api/Users.js';
export default {
    inject: ['reload'],
    data() {
        return {
            arrList: [],
            pageIndex: 1,
            pageSize: 5,
            defaultImg: require('../../assets/img/tokens/USDT.png'),
            totalCNY: 0,
        };
    },
    components: { empty, CustomPagination },
    computed: {
        ...mapState(['screenWidth', 'address', 'info', 'coinConfig', 'balance','lang']),
    },
    async mounted() {
        this.init();
    },
    watch: {},
    methods: {
        async transferFn(item) {
            this.$store.commit('SET_ASSET_PARAMS', { type: 3, coinid: item.coinid });
            this.reload();
        },
        async withdrawFn(item) {
            this.$store.commit('SET_ASSET_PARAMS', { type: 2, coinid: item.coinid });
            this.reload();
        },
        async init() {
            this.arrList = (await Balance()).data;
            let a = BigInt(0);
            this.arrList.forEach(item => {
                let BigIntB = BigInt(this.$toWei(item.balance, 18));
                let BigIntPrice = BigInt(this.$toWei(this.coinConfig[item.coinid].price, 18));
                let t = BigIntB * BigIntPrice;
                a += t;
            });
            this.totalCNY = this.$fromWei(a, 36);
        },
    },
};
</script>
<style lang="scss" scoped>
.total {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    height: 140px;
    background: url('../../assets/img/default/totalRight.png') no-repeat, linear-gradient(90deg, #041cde 0%, #4b7bf8 100%);
    background-position: top right;
    border-radius: 15px;
    justify-content: center;
    padding: 20px 30px;
    box-sizing: border-box;
    & > p {
        margin-bottom: 0px !important;
        img {
            width: 24px;
            height: 24px;
            margin-right: 12px;
        }
        span {
            font-size: 16px;
        }
    }
    & > span {
        font-size: 36px;
        em {
            font-style: normal;
            font-size: 16px;
        }
    }
}
.hr {
    height: 1px;
    border-color: #454545;
    margin: 20px 0px;
}
.all-medal-content2 {
    width: 100%;
    flex: 1;
    display: flex;
    margin: 0px 0px !important;
}
.box3 {
    background: #1a1a1d;
    border-radius: 15px;
    padding: 16px 20px;
    & > div:nth-of-type(1) {
        border-bottom: 1px solid #313131;
    }
}
.mb12 {
    row-gap: 12px !important;
    .cardBalance {
        padding: 16px 20px !important;
    }
}
.box2 {
    height: 100px;
    background: linear-gradient(90deg, #041cde 0%, #4b7bf8 100%);
    border-radius: 20px;
}
.grid-container2 {
    row-gap: 12px !important;
    grid-template-columns: repeat(1, 1fr) !important;
}
.grid-container3 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}
.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    .item2 {
        display: flex;
        align-items: center;
        padding: 16px 20px;
        img {
            width: 50px;
            height: 50px;
        }
        p {
            margin-left: 16px;
            display: flex;
            flex-direction: column;
            margin-bottom: 0px !important;
            & > span:nth-of-type(1) {
                font-size: 14px;
                color: rgba($color: #fff, $alpha: 0.8);
            }
            & > span:nth-of-type(2) {
                font-size: 20px;
            }
        }
    }
}

.cardBalance {
    padding: 30px 40px;
    & > div:nth-of-type(1) {
        & > p:nth-of-type(1) {
            img {
                width: 36px;
                height: 36px;
            }
            span {
                font-size: 16px;
                margin-left: 8px;
            }
        }
        & > p:nth-of-type(2) {
            font-size: 14px;
            color: rgba($color: #fff, $alpha: 0.8);
        }
    }
    & > div:nth-of-type(2) {
        padding: 20px 0px 10px;
        & > p {
            font-size: 24px;
            margin-bottom: 0px;
        }
        & > div {
            button {
                width: 74px;
                height: 34px;
                background: #0421df;
                border-radius: 10px;
            }
            button + button {
                margin-left: 20px;
            }
        }
    }
    & > p {
        margin-bottom: 0px !important;
    }
}
</style>
